$(document).ready(function () {
    if (typeof window.umami !== 'undefined') {
        window.umamiTrackerEnabled = true;

        if ($('#meId').length) {
            const identifyParams = {
                id: $('#meId').val(),
                email: $('#meEmail').val(),
                name: $('#meName').val(),
            };

            window.umami.identify(identifyParams);
        }
    }
});